<template>
  <div class="mem-navigation" :style="{ left: `${left}px` }" v-if="userData">
    <profile-photo :height="58" :width="58" />
    <div class="nav-data">
      <div class="nav-user" :class="{ 'has-profile-link': showProfileUrl }">
        <div class="mem-profile-title">{{ userData.first_name }}</div>
        <div class="mem-profile-title">{{ userData.last_name }}</div>
        <div class="user-status" v-if="hasSpartanPlus">Spartan edge</div>
      </div>
      <a
        v-if="showProfileUrl"
        :href="profileUrl"
        class="profile-link"
        target="_blank"
      >
        {{ $t("profile") }}
      </a>
      <div class="nav-links">
        <div
          class="nav-link"
          v-if="showHomeLink"
          :class="{ 'active-link': navLinks.home.includes($route.name) }"
          @click="openRoute('home')"
        >
          {{ $t("home") }}
        </div>
        <div
          class="nav-link"
          :class="{ 'active-link': navLinks.events.includes($route.name) }"
          @click="openRoute('events')"
        >
          {{ $t("events") }}
          <div v-if="tickets.length !== 0" class="link-counter">
            {{ tickets.length }}
          </div>
        </div>
        <div
          class="nav-link two-line"
          :class="{ 'active-link': navLinks.results.includes($route.name) }"
          @click="openRoute('results')"
        >
          <span>{{ $t("results") }}</span>
          <span>& {{ $t("Photos") }}</span>
        </div>

        <div
          v-if="showCodesView"
          class="nav-link"
          :class="{ 'active-link': navLinks.codes.includes($route.name) }"
          @click="openRoute('my-codes')"
        >
          {{ $t("my_codes") }}
        </div>

        <div
          class="nav-link"
          :class="{ 'active-link': navLinks.teams.includes($route.name) }"
          @click="openRoute('teamsList')"
        >
          {{ $t("teams") }}
        </div>

        <div
          v-if="showTropaionLink"
          class="nav-link"
          :class="{ 'active-link': navLinks.tropaion.includes($route.name) }"
          @click="openRoute('tropaion')"
        >
          {{ $t("tropaion") }}
        </div>
        <div
          class="nav-link"
          :class="{ 'active-link': navLinks.benefits.includes($route.name) }"
          @click="openRoute('benefits')"
        >
          {{ $t("benefits") }}
        </div>
        <div
          v-if="showVolunteerView"
          class="nav-link"
          :class="{ 'active-link': navLinks.volunteers.includes($route.name) }"
          @click="openRoute('volunteers')"
        >
          {{ $t("volunteer") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { uniq } from "lodash";
import { UrlPath } from "@/common/helpers";
import localizeResolver from "@/common/mixins/localizeResolver";
import { appLinksParams } from "@/common/mixins";
import { mapActions, mapGetters } from "vuex";

import ProfilePhoto from "./ProfilePhoto.vue";

export default {
  name: "Navigation",
  mixins: [localizeResolver, appLinksParams],
  data: () => ({}),
  props: ["position"],
  components: {
    ProfilePhoto,
  },
  computed: {
    ...mapGetters(["userData", "unassignedTickets", "hasUnclaimedTickets"]),
    tickets() {
      return uniq(this.unassignedTickets);
    },
    left() {
      return this.position;
    },
    navLinks() {
      return {
        home: ["home"],
        events: ["events", "eventPage"],
        results: ["results", "resultPage"],
        codes: ["my-codes"],
        teams: [
          "teamsList",
          "teamCreate",
          "teamEdit",
          "teamLeaderboard",
          "teamEvents",
        ],
        tropaion: ["tropaion", "challenges"],
        benefits: ["benefits", "UnbreakablePassPage", "SpartanPlusPage"],
        volunteers: ["volunteers"],
      };
    },
    showProfileUrl() {
      return ["US", "MX", "CA", "GB"].includes(this.userCountry);
    },
    profileUrl() {
      let homeUrl = this.localizeConfig?.homeUrl;
      return UrlPath.join(`/race/profile/${this.userData.id}`, homeUrl);
    },
    hasSpartanPlus() {
      return this.userData && this.userData.spartan_plus_member;
    },
    showHomeLink() {
      return ["US"].includes(this.userCountry);
    },
  },
  methods: {
    ...mapActions(["getUserData"]),
    openRoute(name) {
      this.$router.push({ name }).catch(() => {});
    },
  },
  watch: {
    hasUnclaimedTickets(val, oldVal) {
      // console.log(`### hasUnclaimedTickets ${oldVal} -> ${val}`);
      if (!!val !== !!oldVal) {
        this.getUserData();
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.mem-navigation {
  background-color: #000000;
  display: grid;
  grid-template-columns: 58px 1fr;
  min-width: 210px;
  max-width: 222px;
  position: fixed;
  padding-top: 30px;
  @media screen and (max-width: 499px) {
    display: none;
  }
  .nav-data {
    margin-left: 14px;
    color: #ffffff;
    .nav-user {
      text-transform: uppercase;
      letter-spacing: 0.02em;
      margin-bottom: 40px;
      word-break: break-word;
      padding-right: 5px;
      .user-status {
        font-size: 14px;
        font-weight: bold;
        line-height: 14px;
        color: #cf1019;
        margin-top: 2px;
      }

      &.has-profile-link {
        margin-bottom: 20px;
      }
    }

    .profile-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 79px;
      height: 24px;
      background: #1d1d1d;
      border-radius: 30px;
      text-decoration: none;
      margin-bottom: 40px;
      padding: 0 15px;
      text-transform: uppercase;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      color: #ffffff;

      &:hover {
        background: #353535;
      }

      &:active {
        background: #131313;
      }
    }
  }
  .nav-links {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .nav-link {
      position: relative;
      line-height: 16px;
      margin-bottom: 36px;
      border-bottom: 3px solid #020000;
      transition: 0.2s ease;
      color: #9d9d9d;
      @include cursorPointer;
      &.two-line {
        display: flex;
        flex-direction: column;
      }
      .link-counter {
        position: absolute;
        top: -5px;
        right: -12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 16px;
        width: 16px;
        background-color: #cc092f;
        border-radius: 100px;
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.0125em;
        color: #ffffff;
      }
    }
    .active-link {
      color: #ffffff;
      border-bottom: 3px solid #ffffff;
    }
  }
}
</style>
